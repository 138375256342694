<template>
  <div class="px-10 pt-12">
    <h2 class="mb-10">Apple Health</h2>
    <img class="mx-auto h-64 hlg:h-full" src="@/assets/images/iphone.png" alt="">
    <div style="bottom: 28%;" class="absolute bottom-0 left-0 right-0 px-10">
      <router-link :to="{name:'registerEmail'}"
                   class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center ">
        Enable Health
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Health"
}
</script>

<style scoped>

</style>
